import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

interface NotoProTips {
  text: string;
  id: number;
  created_by: string;
  created_at: string;
  user_ids: number[];
  clinical_regions: string[];
  is_network_tip: boolean;
  type: string;
  image_url: string;
}

const useNotoProTips = (
  clinicianEmail: string,
  options?: UseQueryOptions<NotoProTips[], Error>
): UseQueryResult<NotoProTips[], Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    ["noto-pro-tips", accessToken, clinicianEmail] as const,
    async ({ signal }) =>
      api
        .get<NotoProTips[]>("/v1/clinicians/noto_pro_tips", {
          headers: {
            Authorization: accessToken,
          },
          params: { clinician_email: clinicianEmail },
          signal,
        })
        .then(({ data }) => data),
    {
      ...options,
      enabled: !!accessToken && !!clinicianEmail,
    }
  );
};

export default useNotoProTips;
